import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";

const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    WEBAPP: "Web Apps",
    PROJECT: "Projects",
    WEBSITE: "Websites",
    MOCKUP: "Mock Ups"
    // VIMEO: "Vimeo Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "Privileged Pieces",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "A responsive e-commerce web application for Priviliged Pieces. They specialize in authentic, handmade jade jewelry pieces.",
        client: "Privileged Pieces",
        technologies: "React, Express, MongoDB, Node.js, Stripe, Bootstrap, CSS",
        industry: "E-Commerce",
        date: "February, 2023",
        url: {
          name: "privilegedpiece.com",
          link: "https://privilegedpiece.com",
        },
        
        sliderImages: [
          "images/projects/privsamples/homeshotii.jpg",
          "images/projects/privsamples/contactshotii.jpg",
          "images/projects/privsamples/loginshotii.jpg",
          "images/projects/privsamples/prodshotii.jpg",
          "images/projects/privsamples/ringshotii.jpg",
          "images/projects/privsamples/stripeshotii.jpg",
        ],
      },

      thumbImage: "images/projects/privsamples/privlogo-darkgrad.jpg",

      categories: [filters.WEBAPP],
    },
    {
      title: "Riley Luxury Travel",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "A responsive travel web-application designed for helping people plan luxury trips.",
        client: "Riley Luxury Travel",
        technologies: "React, Express, MongoDB, Node.js, Bootstrap, CSS",
        industry: "E-Commerce",
        date: "February, 2023",
        url: {
          name: "Mock Up Link",
          link: "https://rlt-5b5ed51d48f8.herokuapp.com/",
        },
        
        sliderImages: [
          // "images/projects/rltsamples/RLTLogo.jpg",
        ],
      },

      thumbImage: "images/projects/rltsamples/RLTLogo.jpg",

      categories: [filters.MOCKUP],
    },
    {
      title: "Password Generator",
      type: types.DOCUMENT,
      document: { 
        projectInfo:
        "A responsive, single page website that generates a randomized password that satisfies the user's requirements.",

      client: "User",
      technologies: "Javascript, Node.js, HTML, CSS",
      industry: "Utilities",
      date: "September, 2022",
       url: {
          name: "Heroku Site",
          link: "https://psswrdgnr-e28e6cad6583.herokuapp.com/",
        },
        
        // sliderImages: [
        //   "images/projects/project-2.jpg",
        //   "images/projects/project-5.jpg",
        // ],
      },
      

      thumbImage: "images/projects/pssgennail.jpg",

      categories: [filters.PROJECT],
    },
    // {
    //   title: "Password Generator",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-2.jpg",

    //   categories: [filters.PROJECT],
    // },
    {
      title: "Embrace My Brows",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "A responsive, single page website that gives a brief overview of 'Embrace My Brows' with a link to schedule appointments.",
        client: "Smurfette",
        technologies: "Javascript, Node.js, CSS, Bootstrap",
        industry: "Goods & Services",
        date: "September, 2021",
        url: {
          name: "embracemybrows.com",
          link: "http://www.embracemybrows.com",
        },

        // sliderImages: [
        //   "images/projects/browlogoii.jpg"
        // ],
      },

      thumbImage: "images/projects/browlogoii.jpg",
      categories: [filters.WEBSITE],
    },
    // {
    //   title: "Embrace My Brows",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: false,
    //     id: "PMNnEEEacCg",
    //   },
    //   thumbImage: "images/projects/project-3.jpg",

    //   categories: [filters.WEBSITE],
    // },
    // {
    //   title: "Vimeo Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: true,
    //     id: "259411563",
    //   },

    //   thumbImage: "images/projects/project-4.jpg",
    //   categories: [filters.VIMEO],
    // },
    // {
    //   title: "Detailed Project 2",
    //   type: types.DOCUMENT,
    //   document: {
    //     projectInfo:
    //       "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //     client: "Ruby Clinton",
    //     technologies: "iOS, HTML5, CSS3, PHP, Java",
    //     industry: "Art & Design",
    //     date: "July 16, 2019",
    //     url: {
    //       name: "www.example.com",
    //       link: "https://www.example.com",
    //     },
    //     sliderImages: [
    //       "images/projects/project-1.jpg",
    //       "images/projects/project-2.jpg",
    //     ],
    //   },
    //   thumbImage: "images/projects/project-5.jpg",
    //   categories: [filters.DETAILED],
    // },
    // {
    //   title: "Mockups Design 2",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-6.jpg",

    //   categories: [filters.MOCKUPS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            {/* <span className="bg-primary text-dark px-2">Portfolio</span> */}
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            {/* <span className="text-light"> Demo III</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
